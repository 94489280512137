import React from "react"
import { Link, graphql } from "gatsby"
import Moment from "react-moment"
import "moment-timezone"
import "moment/locale/de"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider from "../components/slider"
import styled from "styled-components"
import Img from "gatsby-image"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { FaYoutube, FaFlickr } from "react-icons/fa"
import { IconContext } from "react-icons"
import { RichText } from "prismic-reactjs"
import Eventlist from "../components/eventlist"
import Speakerlist from "../components/speakerlist"

const A = styled.a`
  h2 {
    padding: 1rem;
    display: block;
  }
  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`
const EventLink = styled(Link)`
  color: black;
  text-decoration: none;
  width: auto;
  padding: 0 0;
  color: #000;
  text-decoration: none;
  font-weight: 500;
  display: inline !important;
`
const SpeakerLink = styled(Link)`
  color: black;
  text-decoration: none;
  width: auto;
  padding: 0 0;
  color: #000;
  text-decoration: none;
  font-weight: 500;
  display: inline !important;
`
const Content = styled.div`
  max-width: 1200px;
  margin: 6.5rem auto 0 auto;
  scroll-behavior: smooth;
`
const Heading = styled.h1`
  padding: 0 1rem;
`
const HeadingInside = styled.h1`
  padding: 0rem 0;
  margin-bottom: 0rem;
  margin-top: 0;
`
const WeekendList = styled.div`
  display: flex;
  flex-direction: column;

  transition: 0.3s all cubic-bezier(0.215, 0.61, 0.355, 1);
  @media only screen and (max-width: 800px) {
  }
`
const Weekend = styled.div`
  transition: 0.4s all cubic-bezier(0.215, 0.61, 0.355, 1);
  a {
    color: black;
    text-decoration: none;
  }
  h2 {
    margin-bottom: 0.4rem;
  }
  :hover h3 {
    border-bottom: 2px solid ${props => props.color};
  }
  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`
const Speaker = styled.div`
  padding: 1rem;
  width: 50%;
  transition: 0.4s all cubic-bezier(0.215, 0.61, 0.355, 1);
  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
  a div a {
    padding: 0 0;
  }
  a div a:hover {
    color: #83b413;
  }
  a {
    width: 100%;
    padding: 0 1rem;
    color: #000;
    text-decoration: none;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  a h1:hover {
    color: #f9929b;
  }

  a h1,
  h2 {
    margin-bottom: 0;
    margin-top: 0;
    transition: 0.1s all cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  a div {
    width: 100%;
  }
  a blockquote {
    margin: 1rem 0 0 0;
  }
  a img  {
    height: 200px;
  }
  @media only screen and (max-width: 600px) {
    a {
      flex-direction: column;
    }
    a img  {
      padding-bottom: 1.5rem;
    }
  }
`

const Text = styled.div`
  padding-left: 1.5rem;
  @media only screen and (max-width: 600px) {
    padding-left: 0;
  }
`
const SpeakerList = styled.div`
  display: flex;
  flex-wrap: wrap;
  transition: 0.3s all cubic-bezier(0.215, 0.61, 0.355, 1);
  :nth-child(3 + 4) {
    align-self: flex-end;
  }
`
const EventList = styled.div`
  display: flex;
  flex-direction: column;
  transition: 0.3s all cubic-bezier(0.215, 0.61, 0.355, 1);
  div {
    width: 66%;
  }
  :nth-child(3 + 4) {
    align-self: flex-end;
  }
  div:nth-child(2) {
    margin-top: -3rem;
  }
`
const Html = styled.div`
  padding: 1rem;
`
const About = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h1 {
    width: 66%;
  }
  p {
    column-count: 2;
    width: 66%;
  }
  @media only screen and (max-width: 800px) {
    h1 {
      width: 100%;
    }
    p {
      font-size: 1rem;
      column-count: 1;
      width: 100%;
    }
  }
`

const Academy = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  h1 {
    width: 66%;
  }
  p {
    column-count: 2;
    width: 66%;
  }
  h1:nth-child(1) {
    align-self: flex-end;
  }
  p:nth-child(2) {
    align-self: flex-end;
  }
  h1:nth-child(3) {
    align-self: flex-start;
    margin-bottom: 0;
  }
  h1:nth-child(4) {
    align-self: flex-start;
    margin-top: 0;
  }
  @media only screen and (max-width: 800px) {
    h1 {
      width: 100%;
    }
    p {
      font-size: 1rem;

      column-count: 1;
      width: 100%;
    }
  }
`
const Podcast = styled.div`
  display: flex;
  div {
    padding: 0 1rem;
  }
  div:first-child {
    width: 33%;
  }
  div:last-child {
    width: 66%;
  }
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    div:first-child {
      width: 100%;
    }
    div:last-child {
      width: 100%;
    }
  }
  @media only screen and (max-width: 600px) {
    margin-bottom: 450px;
  }
`
const Location = styled.h3`
  font-size: 32px;

  padding: 0 0 1rem 0;
`
const WeekendText = styled.div`
  width: 50%;
  padding-right: 2rem;

  @media only screen and (max-width: 900px) {
    width: 100%;
    p {
      font-size: 1rem;
    }
  }
`
const WeekendContainer = styled.div`
  padding: 1rem;
  display: flex;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
  h1 {
    margin: 0;
  }
`
const FormateContainer = styled.div`
  display: flex;
  h3,
  p {
    text-decoration: none;
    color: black;
  }
  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
`
const FullProgramm = styled.div`
  display: flex;
  justify-content: center;
  a {
    text-decoration: none;
    color: black;
    padding-bottom: 0.6rem;
    border-bottom: 2px solid black;
    transition: 0.1s all cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  a:hover {
    border-bottom: 2px solid #f9929b;
  }
`
const FormatLink = styled(Link)`
  text-decoration: none;
  color: black;
`
const FormateImg = styled.img`
  width: 50%;
  height: 600px;
  object-fit: contain;
  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`
const SlideContainer = styled.div`
  width: 100%;
  padding: 0 1rem;
`
const SlideWrapper = styled.div``
const SliderImage = styled.img`
  height: 600px;
  object-fit: cover;
  @media only screen and (max-width: 800px) {
    height: 400px;
  }
`

const Zahlen = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1050px;
  margin: 0 auto;
  > div {
    display: flex;
    margin-bottom: 1rem;
    max-width: 550px;
    flex-direction: column;
    margin-top: -7rem;
    padding: 0 1rem;
  }
  > div:first-child {
    transform: translateY(0);
    margin-top: 0;
  }
  > div:nth-child(even) {
    align-self: flex-end;
  }
  @media only screen and (max-width: 900px) {
    align-items: center;
    > div {
      margin-top: 0;
    }
    > div:nth-child(even) {
      align-self: center;
    }
  }
`
const Number = styled.div`
  font-weight: bold;
  text-align: center;
`
const Fact = styled.div``
const Highlights = styled.div`
  display: flex;
  > div {
    padding: 0 1rem;
  }
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`
const Youtube = styled.div`
  height: 40vw;
  max-height: 500px;
  min-height: 200px;
  width: 66%;
  @media only screen and (max-width: 900px) {
    width: 100%;
    height: 50vw;
  }
`
const Iframes = styled.div`
  height: auto;
  width: 66 %;
  @media only screen and (max-width: 900px) {
    width: 100 %;
  }
`

const MoreContent = styled.div`
  width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  a {
    color: black;
    opacity: 0.7;
  }
  a:hover {
    opacity: 1;
  }
  @media only screen and (max-width: 900px) {
    margin-top: 1rem;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    div {
      max-width: 45%;
    }
  }
  @media only screen and (max-width: 500px) {
    margin-top: 1rem;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    div {
      max-width: 100%;
    }
  }
`

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const {
      data: { highlights },
    } = this.props
    const data = highlights.allHomepages.edges[0].node
    const events = data.highlighted_events
    const slides = data.slider

    if (typeof window !== "undefined") {
      require("smooth-scroll")('a[href*="#"]', {
        offset: 80,
      })
    }
    events.forEach(function(o) {
      o.additional_event = { ...o.events }
    })

    return (
      <Layout location={this.props.location.pathname}>
        <SEO
          title="GLOBART ACADEMY 2019"
          description="Von 3. - 19. Oktober 2019 findet im ESSL Museum Klosterneuburg die GLOBART Academy zum Thema LEBEN statt. An der Schnittstelle von Kunst und Wissenschaft werden herausragende ImpulsgeberInnen wie Avital Ronell, Jeffrey Sachs, Byung-Chul Han, Trinh Minh Ha, Stephan A. Jansen zu den brennendsten Fragen unserer Zeit sprechen."
          keywords={[
            "GLOBART Academy",
            "Essl Museum",
            "Philosophie",
            "Jeffrey Sachs",
            "Byung Chul Han",
          ]}
          image={data.logo}
        />
        <Content id="top">
          <Html>{RichText.render(data.description)}</Html>
          <A href={data.pdf.url}>
            <h2>Laden Sie hier das neue Magazin herunter.</h2>
          </A>
          <Heading>{RichText.render(data.wochenendtitel)}</Heading>
          <Zahlen>
            <div>
              <Number>
                <h1>114</h1>
              </Number>
              <Fact>
                ImpulsgeberInnen wurden aktiv, das Museum erlaubte ungewöhnliche
                Konstellationen, kleine und große Runden für Ideenaustausch, sie
                waren Projektschmieden, Erfahrungsräume, Erlebnisse, die berührt
                haben, betroffen machten und motiviert haben, selbst aktiv zu
                werden.
              </Fact>
            </div>
            <div>
              <Number>
                <h1>Über 1000</h1>
              </Number>
              <Fact>
                Schüler und Schülerinnen haben aktiv viele Stunden in der
                Ausstellung von Harald Welzer IN.ZUKUNFT gearbeitet, am Bau des
                fliegenden Museums mitgewirkt, beim Workshop "Pflanz die
                Zukunft" über die Natur und Klima gelernt, beim
                Generationendialog Erwachsene in die Pflicht genommen oder mit
                Van Bo Le Mentzel Möbel gebaut und seine Idee von Zusammenleben
                mit einfachen und guten Möglichkeiten zugehört.
              </Fact>
            </div>
            <div>
              <Number>
                <h1>92</h1>
              </Number>
              <Fact>
                Impulsvorträge, Rooms for Change, Künstlergespräche,
                Performances, unvergessliche Konzerte, gemeinsame Mittagstafeln
                und zum Abschluss die Awardverleihung an Carola Rackete.
                Unglaubliche Menschen haben uns alle dabei begleitet! Eine
                Auswahl von ihnen finden sie weiter unten.
              </Fact>
            </div>
            <div>
              <Number>
                <h1>Über 80</h1>
              </Number>
              <Fact>
                Medienberichte in Tages- und Wochenzeitungen, Magazinen, auf Ö1
                - Mittagsjournal, Kulturjournal, Wissen heute, punkt.eins.,
                Journalen, ORF - ZIB, Kulturmontag, NÖ heute, Servus TV...
              </Fact>
            </div>
          </Zahlen>
          {/* <WeekendContainer>
						<WeekendText
							dangerouslySetInnerHTML={{
								__html: highlights.edges[0].node.data.wochenenden.html,
							}}
						/>
						<div>
							<Location>{highlights.edges[0].node.data.location.text}</Location>
							<WeekendList>

							</WeekendList>
						</div>
					</WeekendContainer> */}
          <Heading>Highlights der GLOBART Academy 2019</Heading>
          <Highlights>
            <Youtube>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/videoseries?list=PLRSHqgD1wKLJB3Fhp7Cpzv89qv8CEAhFn"
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowfullscreen
              ></iframe>
            </Youtube>
            <MoreContent>
              <IconContext.Provider value={{ color: "red", size: "3em" }}>
                <div>
                  <p>
                    Besuchen Sie unseren{" "}
                    <a
                      href="https://www.youtube.com/watch?list=PLRSHqgD1wKLJB3Fhp7Cpzv89qv8CEAhFn&v=AWZYYO-QOMM"
                      target="_blank"
                    >
                      Youtube Channel
                    </a>{" "}
                    und erleben Sie die GLOBART Academy 2019 erneut:
                  </p>
                  <a
                    href="https://www.youtube.com/watch?list=PLRSHqgD1wKLJB3Fhp7Cpzv89qv8CEAhFn&v=AWZYYO-QOMM"
                    target="_blank"
                  >
                    <FaYoutube />
                  </a>
                </div>
              </IconContext.Provider>
              <IconContext.Provider value={{ color: "#0063DC", size: "3em" }}>
                <div>
                  <p>
                    Alle Fotos der GLOBART Academy können Sie auf unserem{" "}
                    <a href="https://flic.kr/s/aHsmJosZe7" target="_blank">
                      Flickr Account
                    </a>{" "}
                    durchstöbern:
                  </p>
                  <a href="https://flic.kr/s/aHsmJosZe7" target="_blank">
                    <FaFlickr />
                  </a>
                </div>
              </IconContext.Provider>
            </MoreContent>
          </Highlights>
          <Heading>Future Histories Podcast - Jan Groos</Heading>
          <Podcast id="podcast">
            <div>
              <p>
                Im Rahmen der GLOBART Academy 2019 war Jan Groos mit seinem
                Podcast "Future Histories"{" "}
                <a href="https://www.futurehistories.today">
                  www.futurehistories.today
                </a>{" "}
                eingeladen, einen Workshop zu gestalten. Er hat die Gelegenheit
                genutzt, mit Gästen der GLOBART Academy einige spannende
                Interviews für Future Histories aufzuzeichnen. Das Ergebnis gibt
                es nun zu hören!
              </p>
            </div>
            <Iframes>
              <iframe
                src="https://castbox.fm/app/castbox/player/id2228584/id299775852?v=8.22.11&autoplay=0&hide_list=1"
                frameborder="0"
                width="100%"
                height="200"
              ></iframe>
              <iframe
                src="https://castbox.fm/app/castbox/player/id2228584/id199606281?v=8.11.4&autoplay=0&hide_list=1"
                frameborder="0"
                width="100%"
                height="200"
              ></iframe>
              <iframe
                src="https://castbox.fm/app/castbox/player/id2228584/id203010819?v=8.11.4&autoplay=0&hide_list=1"
                frameborder="0"
                width="100%"
                height="200"
              ></iframe>
              <iframe
                src="https://castbox.fm/app/castbox/player/id2228584/id210419629?v=8.11.4&autoplay=0&hide_list=1"
                frameborder="0"
                width="100%"
                height="200"
              ></iframe>
            </Iframes>
          </Podcast>
          <Heading>Mehr über die Highlights der Academy:</Heading>
          <div style={{ padding: "0 1em" }}>
            <Eventlist additional={events} />
          </div>
          {/* <Academy
						dangerouslySetInnerHTML={{
							__html: highlights.edges[0].node.data.academy.html,
						}}
						id="academy"
					/> */}
          <div id="2019" />
          <Speakerlist
            heading="Referentinnen"
            speakers={data.highlighted_speakers}
          />
          {/*<FullProgramm>
						<Link to="/program">Mehr ReferentInnen entdecken</Link>
					</FullProgramm>
					 <Heading>Formate</Heading>
					<FormatLink to="/formate/">
						<FormateContainer>
							<Html
								dangerouslySetInnerHTML={{
									__html: highlights.edges[0].node.data.formate.html,
								}}
							/>
							<FormateImg src="../enten.jpg" />
						</FormateContainer>
					</FormatLink> */}
        </Content>
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query RueckblickQuery {
    highlights: prismic {
      allHomepages(lang: "de-at") {
        edges {
          node {
            _meta {
              id
            }
            pdf {
              ... on PRISMIC__FileLink {
                _linkType
                name
                size
                url
              }
            }
            title
            logo
            description
            formate
            about
            academy
            wochenendtitel
            wochenenden

            highlighted_speakers {
              speaker {
                ... on PRISMIC_Speaker {
                  title
                  _meta {
                    id
                    uid
                  }
                  quote
                  thumbnail
                  events {
                    ... on PRISMIC_Event {
                      title
                      start_time
                    }
                  }
                }
              }
            }
            highlighted_events {
              events {
                ... on PRISMIC_Event {
                  title
                  end_time
                  start_time
                  _meta {
                    uid
                    id
                  }
                  thumbnail
                  format
                  speaker {
                    ... on PRISMIC_Speaker {
                      title
                      _meta {
                        uid
                        id
                      }
                    }
                  }
                  speakers {
                    additional_speakers {
                      ... on PRISMIC_Speaker {
                        title
                        _meta {
                          uid
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
